@font-face {
  font-family: "BiroScriptPlus";
  src: url("./BiroScript/BiroScriptPlus.ttf") format("truetype");
}

@font-face {
  font-family: "BiroScript-PlusBold";
  src: url("./BiroScript/BiroScriptPlus-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MessinaSansMono-Regular";
  src: url("./MessinaSans/MessinaSansMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RightGrotesk-CompactBlack";
  src: url("./RightGrotesk/RightGrotesk-CompactBlack.otf") format("opentype");
}

@font-face {
  font-family: "RightGrotesk-Mediums";
  src: url("./RightGrotesk/RightGrotesk-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Cloud";
  src: url("./Cloud/Cloud.otf") format("opentype");
}
@font-face {
  font-family: "Cloud2";
  src: url("./Cloud/Cloud2.ttf") format("truetype");
}
